type Node = {
  children?: Node[];
  [key: string]: any;
};

type RichText = {
  [key: string]: any;
  root?: Node;
}

const areAllChildrenEmpty = (node: Node): boolean => {
  if (!('children' in node)) {
    return false; // Leaf node, means it's not empty
  }

  if (node.children?.length === 0 || node.children === undefined || node.children === null) {
    return true;
  }

  return node.children.every(child => areAllChildrenEmpty(child));
}

export const isRichTextEmpty = (richText?: RichText): boolean => {
  if (!richText || !richText.root) {
    return true;
  }

  return areAllChildrenEmpty(richText.root);
}