<template>
  <div class="policy-recommendations" v-if="hasPolicyRecommendations">
    <div class="divider-container">
      <div class="divider"></div>
    </div>
    <div class="header">
      <Icon class="policy-rec-icon" name="policy-recommendations" />
      <Typography variant="h5">
        {{$t("Explore Policy Ideas", 1, { locale: pageLanguage })}}
      </Typography>
    </div>
    <div class="content">
      <RichTextElements 
        :elements="policyRecommendations" 
        class="recommendations"
        :class="{ expanded: isExpanded }"
      />
      <Button
        class="see-more"
        variant="tertiary"
        @click="toggleExpand"
      >
        {{$t(isExpanded ? 'Hide' : 'See More', 1, { locale: pageLanguage })}}
        <template #icon>
            <Icon v-if="isExpanded" name="clear-teal" />
            <Icon v-else name="arrow-down-teal" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
    pageLanguage: {
        type: String,
        default: 'en',
    },
    policyRecommendations: {
        type: Object,
    },
});

const hasPolicyRecommendations = !isRichTextEmpty(props.policyRecommendations)

const isExpanded = ref(false);

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style lang="scss" scoped>
.divider-container {
  margin: 2.4rem 0;
  display: flex;
  justify-content: center;

  .divider {
    flex-grow: 1;
    border-bottom: 1px solid palette(divider);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 2.4rem;

  .policy-rec-icon {
    width: 32px;
    height: 32px;
  }
}

.content {
  margin-left: calc(32px + 10px); // Icon width + gap
  
  @media screen and (max-width: 640px) {
    margin-left: 0;
  }
}

.recommendations {
  max-height: 5.8rem;
  overflow: hidden;
  mask-image: linear-gradient(180deg, #000 20%, transparent);
  
  &.expanded {
    max-height: none;
    mask-image: none;
  }
}

.see-more {
  margin-top: 2rem;
}
</style>
