<template>
    <div class="scroll-progress-wrapper" :style="`top: calc(var(--header-height) - 2px)`">
        <div class="progress-bar" :style="`width: ${scrollPercentage * 100}%`"></div>
    </div>
</template>

<script setup lang="ts">
const { y } = useScroll(document);
const windowHeight = useWindowSize().height;
const documentHeight = useElementSize(document.querySelector('body')).height;

const scrollPercentage = computed(() => {
    return y.value / (documentHeight.value - windowHeight.value);
});
</script>

<style lang="scss" scoped>
.progress-bar {
    height: 0.4rem;
    background-color: color(teal);
    transform: translateY(0.2rem);
    transition: width 50ms;
    z-index: 500;

    @include media-query(phone) {
        transform: none;
    }
}

.scroll-progress-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    @include z-index(header);
}
</style>
